import { CopyAltLight, Paste, TrashLight } from '@profgeosoft-ui/icons';
import { Button, Select } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { BasicParametersEntity } from './BasicParameters.entity';
import type { Stack } from '../../../../packages/data/structures/Stack';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import type { ReactNode } from 'react';

import { LogsIndex } from './api/types';
import { BasicParametersStore } from './BasicParameters.store';
import { LogSelect } from './selects/log-select';
import { MnemonicSelect } from './selects/mnemonic-select';
import { SupplierSelect } from './selects/supplier-select';

import styles from './BasicParameters.module.scss';

type Props = {
  stack: Stack<BaseSettingsScreen>;
  screen: BasicParametersEntity;
};

export const BasicParameters = observer(function BasicParameters({ screen, stack }: Props) {
  const [store] = useState(() => new BasicParametersStore(stack, screen));
  const { t } = useTranslation();
  const {
    isWellboresLoading,
    isWellsLoading,
    isWellLogsLoading,
    wellsOptions,
    wellboresOptions,
    currentWellbore,
    currentWell,
    fetchWells,
    isInitialised,
    setLogsIndex,
    logsIndex,
    clipboard,
    saveToClipboard,
    pasteFromClipboard,
    applyToAllWellbores,
  } = store;
  const gridTitles = ['parameter', 'company', 'log', 'mnemonic'];
  const actionsButtons: Array<{
    text: string;
    icon: (classname: string) => ReactNode;
    handleClick: () => void;
    disabled?: boolean;
  }> = [
    {
      text: 'applyToAllBoreHoles',
      icon: (classname) => <Paste className={classname} />,
      handleClick: () => {
        applyToAllWellbores();
      },
    },
    {
      text: 'copyAll',
      icon: (classname) => <CopyAltLight className={classname} />,
      handleClick: () => {
        saveToClipboard();
      },
    },
    {
      text: 'paste',
      icon: (classname) => <Paste className={classname} />,
      disabled: clipboard.parameters.length === 0 || clipboard.type !== logsIndex,
      handleClick: () => {
        pasteFromClipboard();
      },
    },
  ];

  useEffect(() => {
    if (!isInitialised) {
      fetchWells();
    }
  }, [fetchWells, isInitialised]);

  return (
    <article className={styles.column}>
      <div className={styles.header}>
        <div className={styles.headerSelectWrapper}>
          <span>{t('settings:well')}</span>
          <Select
            loading={isWellsLoading}
            disabled={isWellsLoading}
            options={wellsOptions}
            className={styles.headerSelect}
            value={currentWell ? currentWell.name : null}
            onSelect={(value) => {
              store.setCurrentWell(value);
            }}
          />
        </div>
        <div className={styles.headerSelectWrapper}>
          <span>{t('settings:borehole')}</span>
          <Select
            loading={isWellboresLoading}
            options={wellboresOptions}
            disabled={!currentWell || isWellboresLoading}
            className={styles.headerSelect}
            value={currentWellbore ? currentWellbore.name : null}
            onSelect={(value) => {
              store.setCurrentWellbore(value);
            }}
          />
        </div>
      </div>
      <div
        className={clsx(styles.actionsPanel, (!currentWellbore || isWellLogsLoading) && styles.actionsPanel__disabled)}
      >
        <div className={clsx(styles.actionsPanelSwitcher)}>
          <button
            onClick={() => setLogsIndex(LogsIndex.TIME)}
            className={clsx(styles.switcherButton, logsIndex === LogsIndex.TIME && styles.switcherButton__active)}
          >
            <p className={clsx(styles.text, logsIndex === LogsIndex.TIME && styles.text__active)}>
              {t('settings:time')}
            </p>
          </button>
          <button
            onClick={() => setLogsIndex(LogsIndex.DEPTH)}
            className={clsx(styles.switcherButton, logsIndex === LogsIndex.DEPTH && styles.switcherButton__active)}
          >
            <p className={clsx(styles.text, logsIndex === LogsIndex.DEPTH && styles.text__active)}>
              {t('settings:depth')}
            </p>
          </button>
        </div>
        {actionsButtons.map((action) => (
          <div
            onClick={action.handleClick}
            key={action.text}
            className={clsx(styles.actionWrapper, action.disabled && styles.actionWrapper__disabled)}
          >
            <div>{action.icon(clsx(styles.actionIcon))}</div>
            <p>{t(`settings:${action.text}`)}</p>
          </div>
        ))}
      </div>
      <div
        className={clsx(
          styles.parametersSelectionGrid,
          (!currentWellbore || isWellLogsLoading) && styles.parametersSelectionGrid__disabled
        )}
      >
        {gridTitles.map((text) => (
          <div key={text} className={styles.parametersTitle}>
            {t(`settings:Headers:${text}`)}
          </div>
        ))}
        <div></div>
        <div></div>
        {store.paramsLines.map((line) => {
          return (
            <>
              <div key={`${line.parameterType}-name`} className={styles.parametersSelectionGrid_parameter}>
                {t(`settings:Parameters:${line.name}`)}
              </div>
              <SupplierSelect line={line} store={store} />
              <LogSelect line={line} store={store} />
              <MnemonicSelect line={line} store={store} />
              <Button
                key={`${line.parameterType}-delete`}
                variant="danger"
                size="s"
                disabled={line.isLoading}
                onClick={() => line.clearParameter()}
              >
                <TrashLight />
              </Button>
              <div key={`${line.parameterType}-noParam`} className={styles.parametersSelectionGrid_warning}>
                {line.isNoParameter && t('settings:noParameter')}
              </div>
            </>
          );
        })}
      </div>
    </article>
  );
});
