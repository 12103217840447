import type { BasicParametersEntity } from '../../basic-parameters/BasicParameters.entity';
import type { TRenderScreen, TSettingsScreenOptions } from '../SettingsScreenRendererService';

import { BasicParameters } from '../../basic-parameters/BasicParameters';

export function renderBasicParametersScreen(
  screen: BasicParametersEntity,
  options: TSettingsScreenOptions
): TRenderScreen {
  return () => <BasicParameters stack={options.stack} screen={screen} />;
}
