import type { IWell, IWellbore, IWellLog, LogsIndex } from './types';

import { wellDirectoryServiceAgent as agent } from '../../../../../api/well-directory-service-agent';

export class WellApi {
  async getWells(): Promise<IWell[]> {
    const wells = await agent.get<IWell[]>('wells');

    return wells.data;
  }

  async getWellbores(wellId: number): Promise<IWellbore[]> {
    const wellbores = await agent.get<IWellbore[]>(`wells/${wellId}/wellbores`);

    return wellbores.data;
  }

  async getLogs(wellId: number, wellboreId: number, logIndex: LogsIndex): Promise<IWellLog[]> {
    const logs = await agent.get<Array<IWellLog>>(`wells/${wellId}/wellbores/${wellboreId}/logs?index=${logIndex}`);

    return logs.data;
  }
}
