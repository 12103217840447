import type { Stack } from '../../../../packages/data/structures/Stack';

import { BaseSettingsScreen } from '../screen/BaseSettingsScreen';

export class BasicParametersEntity extends BaseSettingsScreen {
  readonly type = 'basic-parameters';

  constructor(stack: Stack<BaseSettingsScreen>) {
    super(stack);
  }
}
