import { Select } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { TOption } from '../../../../dashboard/features/controls/types';
import type { BasicParametersStore } from '../BasicParameters.store';
import type { ParameterLine } from '../ParameterLine.store';

interface Props {
  line: ParameterLine;
  store: BasicParametersStore;
}
export const SupplierSelect = observer(function SupplierSelect({ line, store }: Props) {
  const { t } = useTranslation();
  const { isBaseParamsLoading } = store;
  const options: TOption[] = line.suppliersOptions.map((supp) => {
    return {
      value: supp.value,
      label: supp.value === 'noSupplier' ? t(supp.label) : supp.label,
    };
  });

  const suppName = line.supplier.value?.id === 'noSupplier' ? t(line.supplier.value.name) : line.supplier.value?.name;

  return (
    <Select
      loading={line.isLoading || isBaseParamsLoading}
      disabled={line.isLoading || isBaseParamsLoading}
      key={`${line.parameterType}-supplier`}
      options={options}
      onSelect={(_, option) => {
        line.setSupplier(option as TOption);
      }}
      value={suppName}
    />
  );
});
