import { action, computed, makeObservable, observable } from 'mobx';

import type { IWellLog, ICurve, IBaseParameter } from './api/types';
import type { TOption } from '../../../dashboard/features/controls/types';

import { BaseParameterTypes } from './api/types';

interface Select {
  options: TOption[] | undefined;
  action: () => void;
  value: { id: number | string; name: string } | null;
}

const parametersMap = new Map<BaseParameterTypes, string>([
  [BaseParameterTypes.DEPTH_HOLE, 'holeDepth'],
  [BaseParameterTypes.AUTO_RECOGNIZED_OPERATIONS, 'operation'],
  [BaseParameterTypes.DEPTH_BIT, 'bitDepth'],
  [BaseParameterTypes.AUTO_RECOGNIZED_EVENTS, 'event'],
  [BaseParameterTypes.HOOKLOAD, 'hookload'],
  [BaseParameterTypes.STANDPIPE_PRESSURE, 'standpipePressure'],
  [BaseParameterTypes.ROTARY_TORQUE, 'rotaryTorque'],
  [BaseParameterTypes.BLOCK_POSITION, 'blockPosition'],
  [BaseParameterTypes.MUD_FLOW_IN, 'mudFlowIn'],
  [BaseParameterTypes.ROTARY_SPEED, 'rotarySpeed'],
  [BaseParameterTypes.WEIGHT_ON_BIT, 'weightOnBit'],
  [BaseParameterTypes.RATE_OF_PENETRATION, 'penetrationRate'],
]);

export class ParameterLine {
  @observable readonly name: string;

  @observable readonly parameterType: BaseParameterTypes;

  @observable readonly curveId: number | null = null;

  @observable private wellLogs: IWellLog[] = [];

  @observable isNoParameter: boolean = false;

  @observable logsCurves: ICurve[] = [];

  @observable supplier: Select = {
    options: this.suppliersOptions,
    action: () => this.setSupplier,
    value: null,
  };

  @observable log: Select = {
    options: this.logsOptions,
    action: () => this.setLog,
    value: null,
  };

  @observable mnemonic: Select = {
    options: this.mnemonicsOptions,
    action: () => this.setMnemonic,
    value: null,
  };

  @observable isLoading: boolean = false;

  constructor(
    parameter: IBaseParameter,
    private updateBaseParameter: (
      setLoading: (value: boolean) => void,
      parameter: BaseParameterTypes,
      curveId?: number
    ) => void,
    private altNames: boolean
  ) {
    this.parameterType = parameter.parameter;
    const name = parametersMap.get(this.parameterType);
    if (name) {
      this.name = name;
    } else {
      this.name = parameter.parameter;
    }

    if (parameter.curveId) {
      this.curveId = parameter.curveId;
    }
    makeObservable(this);
  }

  @computed
  get suppliersOptions(): TOption[] {
    if (this.wellLogs) {
      const suppsId = new Set();
      return this.wellLogs.reduce<TOption[]>((options, log) => {
        if (!log.supplier && !suppsId.has('noSupplier')) {
          options.push({
            value: 'noSupplier',
            label: 'settings:noSupplier',
          });
          suppsId.add('noSupplier');
          return options;
        }
        if (log.supplier && !suppsId.has(log.supplier.id)) {
          options.push({
            value: log.supplier.id,
            label: log.supplier.name,
          });
          suppsId.add(log.supplier.id);
        }
        return options;
      }, []);
    }
    return [];
  }

  @computed
  get logsOptions(): TOption[] {
    if (this.wellLogs) {
      return this.wellLogs.reduce<TOption[]>((options, log) => {
        if (!log.supplier && this.supplier.value?.id === 'noSupplier') {
          options.push({
            value: log.id,
            label: 'noLogName',
          });
          return options;
        }
        if (log.supplier && log.logName && log.supplier.id === this.supplier.value?.id) {
          options.push({
            value: log.id,
            label: log.logName.name,
          });
        }
        return options;
      }, []);
    }
    return [];
  }

  @computed
  get mnemonicsOptions(): TOption[] {
    return this.logsCurves
      .reduce<TOption[]>((options, curve) => {
        let label = curve.parameter.displayMnemonic;
        if (this.altNames && curve.parameter.generalName) {
          label = curve.parameter.generalName;
        }
        options.push({
          value: curve.goStreamId,
          label,
        });

        return options;
      }, [])
      .filter((option, index, array) => array.findIndex((option2) => option.value === option2.value) === index);
  }

  @action.bound
  updateWellLogs(newLogs: IWellLog[]) {
    this.wellLogs = newLogs;
  }

  @action.bound
  setLog(option: TOption | null) {
    if (option) {
      const logName = this.wellLogs.find((log) => log.id === option.value);
      if (logName) {
        this.logsCurves = logName.curves;
        this.log.value = { id: option.value, name: option.label };
      } else {
        this.log.value = null;
      }
    } else {
      this.log.value = null;
    }
    this.setMnemonic(null);
  }

  @action.bound
  setSupplier(option: TOption | null) {
    if (option) {
      this.supplier.value = { id: option.value, name: option.label };
    } else {
      this.supplier.value = null;
    }
    this.setLog(null);
    this.setIsNoParameter(false);
  }

  @action.bound
  setMnemonic(option: TOption | null) {
    if (option) {
      if (this.mnemonic.value?.id !== option.value) {
        this.updateBaseParameter(this.setLoading, this.parameterType, Number(option.value));
      }
      this.mnemonic.value = {
        name: option.label,
        id: option.value,
      };
    } else {
      this.mnemonic.value = null;
    }
  }

  @action.bound
  setIsNoParameter(value: boolean) {
    this.isNoParameter = value;
  }

  @action.bound
  setMnemonicFromBaseParams() {
    for (let i = 0; i < this.wellLogs.length; i += 1) {
      const log = this.wellLogs[i];
      const curve = log.curves.find((c) => c.goStreamId === this.curveId);
      if (curve) {
        this.logsCurves = log.curves;
        const supplierId = log.supplier ? log.supplier.id : 'noSupplier';
        const supplierName = log.supplier ? log.supplier.name : 'settings:noSupplier';
        this.supplier.value = { id: supplierId, name: supplierName };
        const logName = log.logName ? log.logName.name : 'noLogName';
        this.log.value = { id: log.id, name: logName };
        this.mnemonic.value = {
          id: curve.goStreamId,
          name:
            this.altNames && curve.parameter.generalName
              ? curve.parameter.generalName
              : curve.parameter.displayMnemonic,
        };
        this.setIsNoParameter(false);
        break;
      }
    }
    if (!this.mnemonic.value) {
      this.setIsNoParameter(true);
    }
  }

  @action.bound
  getMnemonicName(id: number | string) {
    const curve = this.logsCurves.find((c) => c.goStreamId === id);
    if (curve) {
      return curve.parameter.name;
    }
    return null;
  }

  @action.bound
  clearParameter() {
    this.setMnemonic(null);
    this.setLog(null);
    this.setSupplier(null);
    this.updateBaseParameter(this.setLoading, this.parameterType);
  }

  @action.bound
  setLoading(value: boolean) {
    this.isLoading = value;
  }
}
