import { Select } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';

import type { BasicParametersStore } from '../BasicParameters.store';
import type { ParameterLine } from '../ParameterLine.store';

import styles from '../BasicParameters.module.scss';

interface Props {
  line: ParameterLine;
  store: BasicParametersStore;
}

export const MnemonicSelect = observer(function MnemonicSelect({ line, store }: Props) {
  const { isBaseParamsLoading } = store;
  return (
    <Select
      allowClear
      onClear={() => {
        line.setMnemonic(null);
      }}
      loading={line.isLoading || isBaseParamsLoading}
      disabled={!line.log.value || line.isLoading || isBaseParamsLoading}
      key={`${line.parameterType}-mnemonic`}
      onSelect={(value, option) => {
        if (option.label) {
          line.setMnemonic({ value: value, label: String(option.label) });
        }
      }}
      filterOption={(inputValue, option) => {
        if (option && option.label && option.value) {
          const lowerInput = inputValue.toLowerCase();
          return (
            String(option.label).toLowerCase().includes(lowerInput) ||
            String(line.getMnemonicName(option.value)).toLowerCase().includes(lowerInput)
          );
        }
        return false;
      }}
      showSearch
      className={styles.selectSearch}
      dropdownMatchSelectWidth={false}
      value={line.mnemonic.value?.id}
      optionLabelProp="label"
    >
      {line.mnemonicsOptions.map((option) => (
        <Select.Option label={option.label} key={option.value} value={option.value}>
          <span className={styles.selectName}>{line.getMnemonicName(option.value)}</span>{' '}
          <span className={styles.selectMnemo}>{option.label}</span>
        </Select.Option>
      ))}
    </Select>
  );
});
