import type { IBaseParameter } from './types';

import { wellDirectoryServiceAgent } from '../../../../../api/well-directory-service-agent';

export class BaseParametersApi {
  async getBaseParameter(wellboreId: number): Promise<Array<IBaseParameter>> {
    const baseParameters = await wellDirectoryServiceAgent.get<Array<IBaseParameter>>(`baseParameters/${wellboreId}`);
    return baseParameters.data;
  }

  async updateBaseParameter(baseParams: IBaseParameter[]) {
    await wellDirectoryServiceAgent.put('baseParameters/update', baseParams);
  }
}
