import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './NoWellChosen.module.scss';
export const NoWellChosen = observer(function NoWellChosen() {
  const { t } = useTranslation();
  return (
    <div className={styles.noWellContainer}>
      <Image />
      <div className={styles.noWellText}>{t('widgets:noWellChosen')}</div>
    </div>
  );
});

const Image = observer(function Image() {
  return (
    <div>
      <svg fill="none" viewBox="0 0 261 293" width={261} height={293}>
        <path
          fill="var(--image-color)"
          d="M164.922 272.17a3.5 3.5 0 0 0
            6.299-3.053l-6.299 3.053Zm-44.937-100.753h-3.5c0
             .528.12 1.05.351 1.526l3.149-1.526Zm3.5-51.214v-3.5h-7v3.5h7Zm-7
             0v51.214h7v-51.214h-7Zm.351 52.74 48.086 99.227 6.299-3.053-48.086-99.227-6.299 3.053ZM149.381 120.203v-3.5h-7v3.5h7Zm41.33 152.004a3.5 3.5 0 0 0 6.263-3.128l-6.263 3.128Zm-44.83-100.79h-3.5c0 .518.115 1.029.336 1.497l3.164-1.497Zm28.777 60.816-3.164 1.497.033.067 3.131-1.564Zm-32.277-112.03v51.214h7v-51.214h-7Zm.336 52.711 28.777 60.816 6.328-2.994-28.777-60.816-6.328 2.994Zm28.81 60.883 19.184 38.41 6.263-3.128-19.185-38.41-6.262 3.128Z"
        />
        <path
          stroke="var(--image-color)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="7"
          d="m123.689 213.332-19.328 57.311M23.5 113.024h70.43m141.57
          0h-62.316m0 0-12.921-41.916m12.921 41.916-39.627-25.04m39.627
          25.04h-39.627m-39.627 0 12.921-41.916M93.93
          113.024l39.627-25.04m-39.627
          25.04h39.627m-24.288-91.23h46.019m4.975 49.314-26.706 16.876m26.706-16.876h-26.706m26.706 0L150.175 38.38m-43.324 32.727 26.706 16.876m-26.706-16.876h26.706m-26.706 0 10.088-32.727m16.618 49.603v25.04m0-41.916 16.618-32.727m-16.618 32.727L116.939 38.38m33.236 0-5.113-16.587V7h-23.01v14.794l-5.113 16.587m33.236 0h-33.236"
        />
      </svg>
    </div>
  );
});
