import {
  TableLight as TemplateIcon,
  InfoLight as InfoIcon,
  BookOpenLight as BookIcon,
  SettingsDetailLightCustom,
  TrackLightCustom,
  DarhboardAltLight,
} from '@profgeosoft-ui/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEV_SETTINGS_PAGE_AVAILABLE } from 'src/api/consts';
import { useService } from 'src/packages/di';

import { SettingsStore } from './SettingsStore';

import styles from './SettingsGrid.module.scss';

interface ISettingsNav {
  id: string;
  type: TSettingType;
  Icon: JSX.Element;
  disabled?: boolean;
}

export type TSettingType =
  | 'widgetsManager'
  | 'tabsManager'
  | 'unitSystem'
  | 'devSettings'
  | 'basicParameters'
  | 'others';

const dummyContent: ISettingsNav[] = [
  {
    id: '1',
    type: 'widgetsManager',
    Icon: <TemplateIcon className={styles.mainColumnIcon} />,
  },
  {
    id: '2',
    type: 'tabsManager',
    Icon: <BookIcon className={styles.mainColumnIcon} />,
  },
  {
    id: '5',
    type: 'basicParameters',
    Icon: <TrackLightCustom className={styles.mainColumnIcon} />,
  },
  {
    id: '6',
    type: 'others',
    Icon: <DarhboardAltLight className={styles.mainColumnIcon} />,
  },
];

if (process.env.NODE_ENV !== 'production') {
  dummyContent.push({
    id: '3',
    type: 'unitSystem',
    Icon: <InfoIcon className={styles.mainColumnIcon} />,
    disabled: true,
  });
}

if (DEV_SETTINGS_PAGE_AVAILABLE) {
  dummyContent.push({
    id: '4',
    type: 'devSettings',
    Icon: <SettingsDetailLightCustom className={styles.mainColumnIcon} />,
  });
}

export const SettingsGrid = observer(function SettingsGrid() {
  const [settingsStore] = useState(() => new SettingsStore(dummyContent[0].id));

  const screenRendererService = useService('settingsScreenRenderer');

  const { t } = useTranslation();

  const { mainColumn, getNavClickHandler, effect } = settingsStore;

  useEffect(effect, [effect]);

  return (
    <section className={styles.wrapper}>
      <h1 className={styles.header}>{t('sidebar:Nav.settings')}</h1>
      <div className={styles.contentWrapper}>
        <ul className={styles.mainColumn}>
          {dummyContent.map((item) => (
            <li key={item.id} className={styles.mainColumnItem}>
              <button
                type="button"
                onClick={() => getNavClickHandler(item.type)(item.id)}
                disabled={item.disabled}
                className={clsx(styles.mainColumnButton, item.id === mainColumn && styles.mainColumnButton__active)}
              >
                {item.Icon} {t(`settings:${item.type}`)}
              </button>
            </li>
          ))}
        </ul>
        <div className={styles.grid}>
          {settingsStore.stack.stackArray.map((stackItem) => {
            const renderScreen = screenRendererService.renderScreen(stackItem, { stack: settingsStore.stack });

            return <Fragment key={stackItem.$$instanceId}>{renderScreen({})}</Fragment>;
          })}
        </div>
      </div>
    </section>
  );
});
