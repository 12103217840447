import { action, makeObservable, observable } from 'mobx';

import { storage } from '../shared/consts/storage';

export interface IOtherSettingsService {
  isAltNames: boolean;
  selectAltNames(value: boolean): void;
}

export class OtherSettingsStore {
  @observable isAltNames: boolean;

  constructor() {
    const storedAltNames = storage.GET<boolean>('altNames');
    this.isAltNames = storedAltNames != null ? storedAltNames : false;
    makeObservable(this);
  }

  private _setAltNames(value: boolean) {
    this.isAltNames = value;
    storage.SET('altNames', value);
  }

  @action.bound
  selectAltNames(value: boolean) {
    this._setAltNames(value);
  }
}
