import { WellDetailsWidget as GoWellDetailsWidget, getSectionTitle } from '@go-widgets/well-details-widget';
import { hasValue } from '@go-widgets/well-logs-widget/dist/utils';
import {
  EditLightBig as EditIcon,
  FullArrowLight as FullIcon,
  ReduceLight as MinimizeIcon,
} from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';
import { IS_PORTABLE_DEVICE } from 'src/packages/shared/consts/isPortableDevice';

import type { WellDetailsWidgetEntity } from './WellDetailsWidget.entity';
import type { TWidgetRendererOptions } from '../workspace/services/WidgetRendererService';

import { NoWellChosen } from '../no-well-chosen';
import { WidgetHeader } from '../widget-header/WidgetHeader';

import { WellDetailsWidgetStore } from './WellDetailsWidget.store';

import styles from './WellDetailsWidget.module.scss';

type WrapperProps = {
  entity: WellDetailsWidgetEntity;
  options: TWidgetRendererOptions;
};

type Props = {
  store: WellDetailsWidgetStore;
  entity: WellDetailsWidgetEntity;
  options: TWidgetRendererOptions;
};

export const WellDetailsWidgetWrapper = observer(function WellDetailsWidgetWrapper({ entity, options }: WrapperProps) {
  const [store, setStore] = useState<WellDetailsWidgetStore | null>(null);

  const storeService = useService('widgetStoreService');

  useEffect(() => {
    if (store) return;

    const _store = storeService.getStore<WellDetailsWidgetEntity, WellDetailsWidgetStore>(entity);

    if (_store) {
      setStore(_store);
    } else {
      const newStore = new WellDetailsWidgetStore(entity, options.groupSelectStore);

      storeService.setStore<WellDetailsWidgetEntity, WellDetailsWidgetStore>(entity, newStore);

      setStore(newStore);
    }
  }, [setStore, storeService, entity, options.groupSelectStore, store]);

  if (!store) return null;

  return <WellDetailsWidget store={store} entity={entity} options={options} />;
});

export const WellDetailsWidget = observer(function WellDetailsWidget({ entity, options, store }: Props) {
  const { effect, setGroup, exitFullScreen, enableEditMode } = store;

  const { t } = useTranslation();

  const { wellId, isFullscreen, isEditMode, state, setFullscreen } = entity;

  useEffect(effect, [effect]);

  const directories = useService('directories');
  const wells = directories.getObject('GOdashboard_Well');
  const wellName = wells?.find((item) => item.id === wellId)?.data.name ?? wellId;
  const sectionTitle = getSectionTitle(entity.section);

  return (
    <div className={styles.wellDetailsWidgetWrapper}>
      <WidgetHeader
        entity={entity}
        onWidgetChangeGroup={setGroup}
        groupSelectStore={options.groupSelectStore}
        title={`${entity.title} ${wellName ?? ''}`}
        {...(hasValue(sectionTitle) && { sectionTitle })}
        onWidgetDelete={() => {
          options.onWidgetDelete(entity);
        }}
        showCloseButton={!isFullscreen}
        draggable={!isFullscreen}
        renderAdditionalButtons={() => {
          return (
            <>
              {!isEditMode && !IS_PORTABLE_DEVICE && (
                <Tooltip title={t('common:Widget.edit')} delay={400}>
                  <Button
                    onClick={enableEditMode}
                    variant="flat"
                    icon={<EditIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
              {!isFullscreen && (
                <Tooltip title={t('common:Widget.fullscreen')} delay={400}>
                  <Button
                    onClick={() => setFullscreen(true)}
                    variant="flat"
                    icon={<FullIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
              {isFullscreen && (
                <Tooltip title={t('common:Widget.collapse')} delay={400}>
                  <Button
                    onClick={exitFullScreen}
                    variant="flat"
                    icon={<MinimizeIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
            </>
          );
        }}
      />
      {state ? (
        <div className={styles.wrapper}>
          <GoWellDetailsWidget state={state} />
        </div>
      ) : (
        <NoWellChosen />
      )}
    </div>
  );
});
