import { action, computed, flow, makeObservable, observable } from 'mobx';

import type { IBaseParameter, IWell, IWellbore, IWellLog, BaseParameterTypes } from './api/types';
import type { BasicParametersEntity } from './BasicParameters.entity';
import type { Stack } from '../../../../packages/data/structures/Stack';
import type { TOption } from '../../../dashboard/features/controls/types';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';

import { requireService } from '../../../../packages/di';

import { BaseParametersApi } from './api/BaseParameters.api';
import { LogsIndex } from './api/types';
import { WellApi } from './api/Wells.api';
import { ParameterLine } from './ParameterLine.store';

export class BasicParametersStore {
  readonly stack: Stack<BaseSettingsScreen>;
  readonly screenEntity: BasicParametersEntity;
  private readonly wellApi: WellApi;
  private readonly baseParametersApi: BaseParametersApi;
  private readonly notifications = requireService('notifications');
  readonly isAltNames: boolean;

  @observable wells: IWell[] = [];
  @observable wellbores: IWellbore[] = [];
  @observable wellLogs: IWellLog[] = [];

  @observable currentWell: IWell | null = null;
  @observable currentWellbore: IWellbore | null = null;

  @observable paramsLines: ParameterLine[] = [];

  @observable isInitialised: boolean = false;
  @observable isWellboresLoading: boolean = false;
  @observable isWellsLoading: boolean = false;
  @observable isWellLogsLoading: boolean = false;
  @observable isBaseParamsLoading: boolean = false;

  @observable clipboard: {
    type: LogsIndex | null;
    parameters: ParameterLine[];
  } = {
    type: null,
    parameters: [],
  };

  @observable logsIndex: LogsIndex = LogsIndex.TIME;

  constructor(stack: Stack<BaseSettingsScreen>, screen: BasicParametersEntity) {
    this.stack = stack;
    this.screenEntity = screen;
    this.wellApi = new WellApi();
    this.baseParametersApi = new BaseParametersApi();
    this.isAltNames = localStorage.getItem('altNames') === 'true';
    makeObservable(this);
  }

  @computed
  get wellsOptions(): TOption[] {
    return this.wells.map((well) => {
      return {
        label: well.name,
        value: well.id,
      };
    });
  }

  @computed
  get wellboresOptions(): TOption[] {
    return this.wellbores.map((wellbore) => {
      return {
        label: wellbore.name,
        value: wellbore.id,
      };
    });
  }

  @action.bound
  setCurrentWell(id: number | string | null) {
    const well = this.wells.find((well) => well.id === Number(id));
    if (well) {
      this.currentWell = well;
      this.fetchWellbores();
    } else {
      this.currentWell = null;
    }
    this.setCurrentWellbore(null);
    this.paramsLines = [];
  }

  @action.bound
  setCurrentWellbore(id: number | string | null) {
    const wellbore = this.wellbores.find((wellbore) => wellbore.id === Number(id));
    if (wellbore) {
      this.currentWellbore = wellbore;
      this.fetchWellLogs();
    } else {
      this.currentWellbore = null;
    }
  }

  @action.bound
  setLogsIndex(index: LogsIndex) {
    if (index !== this.logsIndex) {
      this.logsIndex = index;
      this.fetchWellLogs();
    }
  }

  @action.bound
  saveToClipboard() {
    this.clipboard = { parameters: this.paramsLines, type: this.logsIndex };
    this.notifications.showSuccessMessageT('settings:dataCopied');
  }

  @action.bound
  pasteFromClipboard() {
    if (this.currentWellbore) {
      if (this.logsIndex === this.clipboard.type) {
        const baseParams = this.paramsLines.reduce((paramsAcc: IBaseParameter[], param) => {
          const copiedMnemonicId = this.clipboard.parameters.find(
            (copied) => copied.parameterType === param.parameterType
          )?.mnemonic.value?.id;
          if (copiedMnemonicId) {
            paramsAcc.push({
              logType: this.logsIndex,
              curveId: Number(copiedMnemonicId),
              wellboreId: Number(this.currentWellbore?.id),
              parameter: param.parameterType,
            });
          }
          return paramsAcc;
        }, []);
        this.updateMultiBaseParameters(baseParams);
      }
    }
  }

  @action.bound
  createParamsLines(paramsApi: IBaseParameter[]) {
    this.paramsLines = paramsApi
      .sort((a, b) => a.parameter.localeCompare(b.parameter))
      .reduce((acc: ParameterLine[], param) => {
        const paramLine = new ParameterLine(param, this.updateBaseParameter, this.isAltNames);
        acc.push(paramLine);
        paramLine.updateWellLogs(this.wellLogs);
        if (param.curveId) {
          paramLine.setMnemonicFromBaseParams();
        }
        return acc;
      }, []);
  }

  @action.bound
  baseParamsFilteredByIndex(params: IBaseParameter[]) {
    return params.filter((p) => p.logType === this.logsIndex);
  }

  @flow.bound
  async *applyToAllWellbores() {
    try {
      for (let i = 0; i < this.wellbores.length; i += 1) {
        if (this.wellbores[i] === this.currentWellbore) {
          continue;
        }
        const baseParams = await this.baseParametersApi.getBaseParameter(this.wellbores[i].id);
        yield;
        this.paramsLines.forEach((pl) => {
          if (pl.mnemonic.value) {
            const targetBP = baseParams.find((bp) => bp.parameter === pl.parameterType);
            if (targetBP && targetBP.logType === this.logsIndex) {
              targetBP.curveId = Number(pl.mnemonic.value.id);
            }
          }
        });
        await this.baseParametersApi.updateBaseParameter(baseParams.filter((bp) => bp.curveId));
        yield;
      }
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadBoreholesData');
    } finally {
      this.notifications.showSuccessMessageT('settings:applyToAllBoreHoles');
    }
  }

  @flow.bound
  async *fetchWells() {
    try {
      this.isWellsLoading = true;
      const wells = await this.wellApi.getWells();
      yield;
      this.wells = wells;
      this.isInitialised = true;
    } catch (error) {
      yield;
      this.isWellsLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadWells');
    } finally {
      this.isWellsLoading = false;
    }
  }

  @flow.bound
  async *fetchWellbores() {
    try {
      this.isWellboresLoading = true;

      if (this.currentWell) {
        const wellbores = await this.wellApi.getWellbores(this.currentWell.id);
        yield;
        this.wellbores = wellbores;
      }
    } catch (error) {
      yield;
      this.isWellboresLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadBoreholesData');
    } finally {
      this.isWellboresLoading = false;
    }
  }

  @flow.bound
  async *fetchWellLogs() {
    try {
      this.isWellLogsLoading = true;

      if (this.currentWell && this.currentWellbore) {
        const wellLogs = await this.wellApi.getLogs(this.currentWell.id, this.currentWellbore.id, this.logsIndex);
        yield;
        this.wellLogs = wellLogs;
        this.fetchBaseParameters();
        yield;
      }
    } catch (error) {
      yield;
      this.isWellLogsLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadData');
    } finally {
      this.isWellLogsLoading = false;
    }
  }

  @flow.bound
  async *fetchBaseParameters() {
    try {
      this.isBaseParamsLoading = true;
      if (this.currentWellbore) {
        const baseParameters = await this.baseParametersApi.getBaseParameter(this.currentWellbore.id);
        yield;
        this.createParamsLines(this.baseParamsFilteredByIndex(baseParameters));
      }
    } catch (error) {
      yield;
      this.isBaseParamsLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadBaseParameters');
    } finally {
      this.isBaseParamsLoading = false;
    }
  }

  @flow.bound
  async *updateBaseParameter(setLoading: (value: boolean) => void, parameter: BaseParameterTypes, curveId?: number) {
    try {
      if (this.currentWellbore) {
        setLoading(true);
        const baseParams: IBaseParameter = {
          logType: this.logsIndex,
          wellboreId: Number(this.currentWellbore.id),
          parameter: parameter,
        };
        if (curveId) {
          baseParams.curveId = curveId;
        }
        await this.baseParametersApi.updateBaseParameter([baseParams]);
        yield;
      }
    } catch (error) {
      yield;
      setLoading(false);
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToUpdateBaseParameters');
    } finally {
      setLoading(false);
    }
  }

  @flow.bound
  async *updateMultiBaseParameters(baseParams: IBaseParameter[]) {
    try {
      this.isBaseParamsLoading = true;
      await this.baseParametersApi.updateBaseParameter(baseParams);
      yield;
      await this.fetchBaseParameters();
      yield;
    } catch (error) {
      yield;
      this.isBaseParamsLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToUpdateBaseParameters');
    } finally {
      this.isBaseParamsLoading = false;
    }
  }
}
