import { Select } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { TOption } from '../../../../dashboard/features/controls/types';
import type { BasicParametersStore } from '../BasicParameters.store';
import type { ParameterLine } from '../ParameterLine.store';

interface Props {
  line: ParameterLine;
  store: BasicParametersStore;
}

export const LogSelect = observer(function LogSelect({ line, store }: Props) {
  const { t } = useTranslation();
  const { isBaseParamsLoading } = store;

  let nonameLogCounter = 0;

  const options: TOption[] = line.logsOptions.map((log) => {
    if (log.label === 'noLogName') {
      nonameLogCounter += 1;
    }

    return {
      value: log.value,
      label: log.label === 'noLogName' ? `${t('settings:noLogName')} ${nonameLogCounter}` : log.label,
    };
  });

  const currLabel = options.find((op) => op.value === line.log.value?.id)?.label || line.log.value?.name;

  return (
    <Select
      loading={line.isLoading || isBaseParamsLoading}
      disabled={!line.supplier.value || line.isLoading || isBaseParamsLoading}
      key={`${line.parameterType}-log`}
      onSelect={(_, option) => {
        line.setLog(option as TOption);
      }}
      options={options}
      value={currLabel}
      labelInValue
    />
  );
});
