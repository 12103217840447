import { BackLight } from '@profgeosoft-ui/icons';
import { Loader } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';
import { CreateTabModal } from 'src/pages/dashboard/features/create-tab-modal/CreateTabModal';
import { RemoveObjectModal } from 'src/pages/dashboard/features/remove-object-modal/RemoveObjectModal';
import { TabsList } from 'src/pages/dashboard/features/tabs/TabsList';
import { Toolbar } from 'src/pages/dashboard/features/toolbar/Toolbar';
import { Workbench } from 'src/pages/dashboard/features/workbench/Workbench';

import type { WellIndexType } from '@go-widgets/well-logs-widget';

import { ConnectionClosedModal } from '../connection-closed-modal/ConnectionClosedModal';
import { NewContentWidgetModal } from '../new-content-widget-modal/NewContentWidgetModal';
import { RetryingTabsRecoveringModal } from '../retrying-tabs-recovering-modal/RetryingTabsRecoveringModal';
import { SaveTabAsTemplateModal } from '../save-tab-as-template-modal/SaveTabAsTemplateModal';
import { SessionsConflictModal } from '../sessions-conflict-modal/SessionsConflictModal';
import { TabCreationHint } from '../tab-creation-hint';
import { TabsRecoveringModal } from '../tabs-recovering-modal/TabsRecoveringModal';

import { WorkspaceStore } from './Workspace.store';
import { MASTER_ID_KEY } from './Workspace.utils';

import styles from './Workspace.module.scss';

export const Workspace = observer(function Workspace() {
  const [workspaceStore] = useState(() => new WorkspaceStore());

  const { t } = useTranslation();

  const {
    isWorkspaceInitProcess,
    isLoading,
    isUpdating,
    hasError,
    actualTab,
    onRemoveTabCancel,
    onRemoveTabAccept,
    isRemoveTabModalOpen,
    onCreateNewTabAccept,
    onCreateNewEmptyTab,
    onCreateNewTabCancel,
    isCreateTabModalOpen,
    isSaveTabAsTemplateModalOpened,
    onSaveTabAsTemplateModalClose,
    onSaveTabAsTemplateModalOpen,
    onSaveTabAsTemplate,
    onUpdateTemplate,
    setTabSavedAsTemplate,
    onDragEnd,
    createTab,
    removeTab,
    changeTabName,
    changeActiveKey,
    onCreateNewContentWidget,
    newContentWidgetController,
    externalTabsIds: disabledTabs,
    onRecoverExternalTabs,
    externalTabsRecoveringController,
    onExternalTabsRecoveringCancel,
    externalTabsRetryRecoveringController,
    onRetryExternalTabsRecovering,
    onRetryExternalTabsRecoveringCancel,
    tabsEntities,
    masterWindowsManager,
    sessionManager,
    isExtraMasterDetected,
    effect,
  } = workspaceStore;

  const {
    sessionRegistrationService: { remoteSessionController, onCloseCurrentSession, onKeepCurrentSession },
  } = sessionManager;

  const { activeKey } = workspaceStore.workspace;
  const workspaceRef = useRef<HTMLDivElement>(null);
  const { isFullscreenMode, enterFullscreen, exitFullscreen } = useService('fullscreenService');
  const { status } = useService('sessionService');
  const authService = useService('authService');

  useEffect(effect, [effect]);

  useEffect(() => {
    workspaceStore.localStorageService.set(MASTER_ID_KEY, workspaceStore.id);
  }, [workspaceStore]);

  if (hasError) {
    return (
      <main className={styles.wrapper}>
        <p>Error. Please reload the page</p>
      </main>
    );
  }

  if (isExtraMasterDetected) {
    return (
      <ConnectionClosedModal
        isOpened={isExtraMasterDetected}
        titleT="modals:MainWindowInstanceDuplication.title"
        descT="modals:MainWindowInstanceDuplication.desc"
      />
    );
  }

  const isConnectionClosed = status === 'closed';

  if (isConnectionClosed) {
    return (
      <ConnectionClosedModal
        isOpened={isConnectionClosed}
        titleT="modals:ClosedSession.title"
        descT="modals:ClosedSession.desc"
        externalAction={authService.logout}
        externalActionT="modals:ClosedSession.bactToLogin"
        externalActionIcon={<BackLight />}
      />
    );
  }

  if (status === 'error') {
    return (
      <SessionsConflictModal
        onCancel={onCloseCurrentSession}
        onConfirm={onKeepCurrentSession}
        isOpened={!!remoteSessionController}
      />
    );
  }

  if (isLoading || isWorkspaceInitProcess) {
    return <Loader size="l" className={styles.loader} />;
  }

  const handleEnterFullscreen = () => {
    enterFullscreen(document.documentElement);
  };

  const onCreateWidget = (type: string, wellIndexType?: WellIndexType) => {
    newContentWidgetController?.resolve({ type, wellIndexType });
  };

  const onCloseNewContentWidgetModal = () => {
    newContentWidgetController?.resolve(null);
  };

  return (
    <main className={clsx(styles.wrapper, actualTab?.hasFullscreenWidget && styles.wrapperFullscreenMode)}>
      {!isFullscreenMode && (
        <section className={styles.header}>
          <TabsList
            getIsTabDisabled={(tab) => !!disabledTabs.find((item) => item === tab.id)}
            onWindowOpen={masterWindowsManager?.openWindow}
            activeKey={activeKey}
            onSetActiveKey={changeActiveKey}
            onDragEnd={onDragEnd}
            onCreateTab={createTab}
            onRemoveTab={removeTab}
            onCloseTab={masterWindowsManager?.closeTab}
            onTabFocus={masterWindowsManager?.focusTab}
            onChangeTabName={changeTabName}
            tabsEntities={tabsEntities}
          />
          <Toolbar
            disabledTabs={disabledTabs}
            onSaveTabAsTemplateModalOpen={onSaveTabAsTemplateModalOpen}
            onUpdateTemplate={onUpdateTemplate}
            onTabSavedAsTemplateChange={setTabSavedAsTemplate}
            onOpenInNewWindow={masterWindowsManager?.openWindow}
            activeKey={activeKey}
            onFullscreen={handleEnterFullscreen}
            onSetActiveKey={changeActiveKey}
            onCreateTab={createTab}
            onRemoveTab={removeTab}
            onTabClose={masterWindowsManager?.closeTab}
            onTabFocus={masterWindowsManager?.focusTab}
            onChangeTabName={changeTabName}
            tabsEntities={tabsEntities}
            onCreateContentWidget={onCreateNewContentWidget}
            onCreateWellListWidget={actualTab?.createWellListWidget}
            onCreateWellsFilterWidget={actualTab?.createWellsFilterWidget}
          />
        </section>
      )}

      {(isUpdating || masterWindowsManager?.isTabsSyncModalOpen) && <Loader className={styles.loader} />}

      {!tabsEntities.length && <TabCreationHint />}

      {actualTab && (
        <Workbench
          key={actualTab.id}
          isFullscreenMode={isFullscreenMode}
          tab={actualTab}
          groupManager={actualTab.groupManager}
          workspaceRef={workspaceRef}
          onExitFullscreen={exitFullscreen}
        />
      )}

      {actualTab && (
        <NewContentWidgetModal
          isOpened={!!newContentWidgetController}
          onCreateWidget={onCreateWidget}
          actualTab={actualTab}
          onCloseModal={onCloseNewContentWidgetModal}
        />
      )}

      <RemoveObjectModal
        title={t('modals:RemoveTabModal.title')}
        desc={t('modals:RemoveTabModal.desc')}
        onCancel={onRemoveTabCancel}
        onConfirm={onRemoveTabAccept}
        isOpened={isRemoveTabModalOpen}
      />

      <CreateTabModal
        onCancel={onCreateNewTabCancel}
        onConfirm={onCreateNewTabAccept}
        onCreateEmptyTab={onCreateNewEmptyTab}
        isOpened={isCreateTabModalOpen}
      />

      <TabsRecoveringModal
        isOpened={!!externalTabsRecoveringController}
        onConfirm={onRecoverExternalTabs}
        onCancel={onExternalTabsRecoveringCancel}
      />

      <RetryingTabsRecoveringModal
        isOpened={!!externalTabsRetryRecoveringController}
        onConfirm={onRetryExternalTabsRecovering}
        onCancel={onRetryExternalTabsRecoveringCancel}
      />

      {actualTab && (
        <SaveTabAsTemplateModal
          tabName={actualTab.name}
          isOpened={isSaveTabAsTemplateModalOpened}
          onClose={onSaveTabAsTemplateModalClose}
          onSave={onSaveTabAsTemplate}
        />
      )}
    </main>
  );
});
