import { Switch } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { OthersEntity } from './Others.entity';
import type { Stack } from '../../../../packages/data/structures/Stack';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';

import { OthersScreenStore } from './OthersScreen.store';

import styles from './Others.module.scss';

type Props = {
  stack: Stack<BaseSettingsScreen>;
  screen: OthersEntity;
};

export const OthersScreen = observer(function OthersScreen({ screen, stack }: Props) {
  const [store] = useState(() => new OthersScreenStore(stack, screen));
  const { t } = useTranslation();

  return (
    <article className={styles.column}>
      <div className={styles.contentWrapper}>
        <div className={styles.settingsBlock}>
          <div className={styles.settingsOption}>
            <Switch
              size="s"
              checked={store.isAltNames}
              onChange={(value: boolean) => {
                store.setIsAltNamesOn(value);
              }}
            />
            <span>{t('settings:Others:publicNames')}</span>
          </div>
        </div>
      </div>
    </article>
  );
});
